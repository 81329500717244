nav-footer {
  color: $w;
  position: relative;
  background: $c-footer-bg;
  margin-top: 50px;

  a {
    text-decoration: none;
  }

  a, a.btn, h4 {
    color: $w;
    &:hover, &.btn-primary:hover {
      color: $w;
      border-color: $w;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto';
  }

  p, label {
    font-size: 13px;
  }


  [class*='icon-']:before  {
    color: $c-blue-2;
  }

  .ftr-top {
    padding: 35px 0;
    border-bottom: 1px solid $c-footer-hr;
    @include xs {
      padding-top: 5px;
    }
    li {
      padding: 0;
      line-height: 14px;
      font-size: 14px;
      margin-bottom: 15px;
    }
  }


  .footer-content {
    .headline {
      text-transform: uppercase;
      color: $w;
      margin-bottom: 25px;
      font-size: 18px;
      @include xs {
        margin-top: 30px;
      }
    }

    .add-links {
      flex-grow: 1;
      ul > li {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    [class*='icon-']:before  {
      font-size: 40px;
      line-height: 40px;
      color: $w;
    }
  }

  a {
    color: $c-footer-links;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $c-footer-links;
    }
  }

  .contact-info {
    @include xs {
      margin-top: 10px;
    }
    .media {
      .header {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
        text-transform: none;
      }
    }
  }

  .features {
    p {
      font-size: 13px;
    }
    li {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
    [class*='icon-'] {
      font-size: 14px;
      line-height: 14px;
      &:before {
        color: $c-footer-links;
      }
    }
  }

  .subscribe {
    p {
      margin-bottom: 10px;
    }
    .subscribe-input {
      text-align: right;
    }
    button {
      margin-top: 14px;
    }
  }

  .ftr-bottom {
    padding-top: 35px;
    padding-bottom: 35px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    @include sm {
      flex-direction: column;
    }

    .payments-and-social {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
    }

    .social {
      a {
        text-decoration: none;
        &:hover {
          &[class*='icon-']:before {
            color: $w;
          }
        }
      }

      [class*='icon-']:before {
        color: #bbd4e3;
        margin-right: 0;
        font-size: 40px;
      }


      li {
        &:last-child {
          padding-right: 0;
          a {
            margin-right: 0;
          }
        }
      }

    }//social


    .payments {
      overflow: hidden;
      img {
        max-width: 100%;
      }
    }
  }

  .icon-chevron-up {
    padding: 15px;
    background: $c-dark-gray-3;
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 25px;
    border-radius: 5px;
    text-decoration: none;
    &:hover {
      background: lighten($c-dark-gray-3,10%);
    }
  }

  .copyright {
    font-size: 0.8125rem;
    @include sm {
      margin-bottom: 2rem;
    }
  }
}
