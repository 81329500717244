h1, h1.headline {
  font-size: 36px;
}

.title {
  font-size: 1.7rem;
  margin: 0 0 15px;
}

.subtitle {
  font-size: 1.5rem;
  margin-top: 20px;
}

.subtitle-note {
  margin-bottom: 1rem;
}

.headline {
  font-size: 28px;
  margin-bottom: 15px;
  font-weight: 500;
  color: $c-headline;
  &.white {
    color: $w;
  }
}

.headline-medium {
  font-size: 22px;
  margin-bottom: 22px;
}

.headline-small {
  font-size: 18px;
  margin-bottom: 15px;
}


