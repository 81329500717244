.review-layout {

  .btn-continue {
    min-width: 7.5rem;

  }

}

.pc2-signature-box {
  background:    $c-signature-bg;
  border:        1px dotted $c-light-gray-4;
  padding:       1rem;
  margin-bottom: 0.625rem;
}

.pc2-signature {
  font-family: 'Cookie', cursive;
  font-size:   250%;
}

.pc2-signature-place {
  border-bottom: 1px solid #aaa;
  height: 2.65rem;
}

.pc2-signature-date {
  margin-top:   0.9rem;
  margin-left:  -0.35rem;
  margin-right: -0.35rem;
}

