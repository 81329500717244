.exclusive-contracts-container {

  @include xs {
    line-height: initial;
  }

  .top-line {
    display: inline-block;
    justify-content: space-between;
    align-items: center;
    h5.header {
      font-size: 18px;
      line-height: 18px;
      color: $c-text-dark-2;
      padding: 20px 0 10px;
      font-weight: 600;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    a {
      font-size: 14px;
      line-height: 14px;
      position: relative;
      top: 10px;
      min-width: 130px;
    }
  }

  .exclusive-contracts {
    background: $w;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;

    position: relative;

    font-size: 16px;
    font-weight: 400;
    padding-bottom: 0px;
    color: $c-h-color;
    text-align: left;
    span.content {
      //background-color: #fff;
      padding-right: 20px;
      display: inline;
    }

    .item {
      position: relative;
      flex-direction: column;
      background: $w;
      border-width: 0px;
      border-radius: 7px;
      overflow: hidden;
      display: inline-block;
      padding-bottom: 20px;

      .img-holder {
        padding: 3px;
        position: relative;
        overflow: hidden;
        background: $w;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          display: block;
          height: auto;
          max-height: 100%;
        }
      }

      .header {
        color: $c-text-dark-3;
        strong {
          color: $c-text-dark-2;
        }
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        padding: 20px 0 2px;
        font-weight: 400;
        margin: 0;
      }

      .subheader {
        color: $c-text-dark-3;
        font-size: 13px;
        text-align: center;
        padding: 0 20px 30px;
        flex-grow: 1;
      }

      .item-info {
        font-size: 0.8em;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        flex-grow: 1;
        padding: 0 15px;

        @include xs {
          padding: 0 5px;
        }
      }
    }
  }
}
