#items-list {
  .search-box {
    max-width: 250px;
    margin-bottom: 10px;
  }
}

.items-table {
  margin-top: 15px;

  .item {

    display: flex;
    flex-wrap: nowrap;
    align-items: center;


    &:nth-child(odd) {
      .i-name {
        background: $c-table-row-bg;
      }
    }

    .i-catnum, .i-name {
      padding: 5px 10px;
    }

    .i-catnum {
      width: 80px;
      min-width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      background: $c-table-code-cell;
      align-self: stretch;
    }

    .i-name {
    }

  }

}