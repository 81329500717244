.main-content {
  margin-top: 10px;
}

.sec-1 {
  margin-bottom: 40px;
  .specialty-slider-holder {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }
}

specialty-slider {
  width: 100%;
}

@import "menu";
@import "carousel";
@import "featured-contracts";
@import "exclusive-contracts";
@import "../../components/star-rating";
@import "customize-exp";
