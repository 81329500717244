.rating {
  unicode-bidi: bidi-override;
  span {
    color: #f2cc1c;
  }

  &.rating-1 {
    span:nth-child(n+2) {
      color: #bbd4e3;
    }
  }
  &.rating-2 {
    span:nth-child(n+3) {
      color: #bbd4e3;
    }
  }
  &.rating-3 {
    span:nth-child(n+4) {
      color: #bbd4e3;
    }
  }
  &.rating-4 {
    span:nth-child(n+5) {
      color: #bbd4e3;
    }
  }
  &.rating-5 {
    span:nth-child(n+6) {
      color: #bbd4e3;
    }
  }
}

.rating > span {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
  font-size: 15px;
  margin-right: 5px;
}
