// break points
$xl: 1200px;
$lg: 1199px;
$md: 1199px;
$sm: 991px;
$xs: 767px;
$xs480: 479px;
@mixin xl{@media(min-width:#{$xl}){@content;}}
@mixin lg{@media(max-width:#{$lg}){@content;}}
@mixin md{@media(max-width:#{$md}){@content;}}
@mixin sm{@media(max-width:#{$sm}){@content;}}
@mixin xs{@media(max-width:#{$xs}){@content;}}
@mixin xs480{@media(max-width:#{$xs480}){@content;}}

@mixin bordered ($paddings:0px, $color:$c-item-border) {
  border: 1px solid $color;
  border-radius: $general-border-radius;
  overflow: hidden;
  padding: $paddings;

  .headline-small {
    font-weight: 600;
  }
}