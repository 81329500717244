@font-face {
    font-family: 'icomoon';
    src:    url('/assets/fonts/icomoon/icomoon.eot');
    src:    url('/assets/fonts/icomoon/icomoon.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/icomoon/icomoon.ttf') format('truetype'),
        url('/assets/fonts/icomoon/icomoon.woff') format('woff'),
        url('/assets/fonts/icomoon/icomoon.svg?#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-question_mark:before {
    content: "\e91c";
}
.icon-big_arrow_right:before {
    content: "\e91b";
}
.icon-star:before {
    content: "\f005";
}
.icon-flag-start:before {
    content: "\f11d";
}
.icon-flag-finish:before {
    content: "\f11e";
}
.icon-question:before {
    content: "\f128";
}
.icon-info:before {
    content: "\f129";
}
.icon-facebook:before {
    content: "\e919";
}
.icon-linkedin:before {
    content: "\e91a";
}
.icon-clock_footer:before {
    content: "\e916";
}
.icon-email_footer:before {
    content: "\e917";
}
.icon-phone_footer:before {
    content: "\e918";
}
.icon-tick:before {
    content: "\e915";
}
.icon-continue_arrow:before {
    content: "\e914";
}
.icon-home:before {
    content: "\e913";
}
.icon-arrow-left:before {
    content: "\e901";
}
.icon-arrow-right:before {
    content: "\e912";
}
.icon-search:before {
    content: "\e911";
}
.icon-chevron-thin-left:before {
    content: "\e902";
}
.icon-chevron-thin-right:before {
    content: "\e906";
}
.icon-triangle-right:before {
    content: "\e90b";
}
.icon-check:before {
    content: "\e90c";
}
.icon-close:before {
    content: "\e910";
}
.icon-chevron-left:before {
    content: "\f0a4";
}
.icon-chevron-right:before {
    content: "\f078";
}
.icon-chevron-up:before {
    content: "\f0a2";
}
.icon-hamburger:before {
    content: "\f05e";
}
.icon-folder:before {
    content: "\e903";
}
.icon-phone:before {
    content: "\e900";
}
.icon-email:before {
    content: "\e907";
}
.icon-location:before {
    content: "\e908";
}
.icon-shedule:before {
    content: "\e909";
}
.icon-mobile:before {
    content: "\e90a";
}
.icon-logout:before {
    content: "\e904";
}
.icon-user:before {
    content: "\e905";
}
.icon-facebook-old:before {
    content: "\e90d";
}
.icon-twitter:before {
    content: "\e90e";
}
.icon-linkedin-old:before {
    content: "\e90f";
}

