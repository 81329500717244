@mixin bordered-container($borderTopWidth, $colorTop) {
  margin-bottom: 3rem;
  max-width: 100%;
  @include xs480 {
    width: 100%;
  }

  .bordered-content {
    display: block;
    border: 1px solid $c-section-border;
    border-radius: $general-border-radius;
    //border-top: $borderTopWidth solid $colorTop;
    border-top: 1px solid $c-section-border;
    padding: 20px 15px 15px;
    margin-top: 30px;
    h2 {
      margin-top: 0;
    }
  }

  .form-group {
    flex-direction: column;
  }

}

.bordered-container {
  @include bordered-container(4px,$c-section-top-border);
}

.bordered-container.thin-top {
  @include bordered-container(1px,$c-section-border);
  .form-group {
    margin-bottom: 20px;
    label {
      margin-bottom: 10px;
      font-family: $f-os;
      font-size: 14px;
      font-weight: 600;
      color: $c-headline;
    }
  }
}