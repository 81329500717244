// Global overrides
html {
  -webkit-font-smoothing: antialiased;
}

body {
  @extend .no-mp;
  font-family: $f-primary;
  color: $c-text-general;
  background: $w;
}

.container {
  @include sm {
    max-width: 100%;
    padding: 0 25px;
  }
}

a {
  color: $c-link;
  text-decoration: underline;

  &:hover, &:focus, &:active {
    color: $c-link;
    text-decoration: none;
    outline: none;
    outline-offset: 0;
  }
}

h1,h2,h3,h4,h5,h6 {
  color: $c-h-color;
  font-family: 'Roboto', serif;
  font-weight: 400;
}


.img-responsive {
  width: 100%;//Bug fix for FireFox with flex mode
}


::selection {
  background: $c-action;
  color: $w;
}
::-moz-selection {
  background: $c-action;
  color: $w;
}

.nav > li > a:focus, .nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
}

.navbar {
  margin-bottom: 0;
}

.input-group {
  input {
    min-width: 1px;
  }
}

input {
  outline: none !important;
}