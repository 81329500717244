.msar-layout {
  a.btn {
    text-decoration: none;
    font-size: 1rem;
  }

  .terms-string {
    font-size: 12px;
    margin: 1rem 0;
  }

  .custom-question {
    font-size: 14px;
    color: $c-question-header;
    font-weight: bold;
    margin: 0;
  }
}

.question-holder {
  margin: 20px 0 0;

  .header {
    color: $c-question-header;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 0;
  }

  .answer-holder {
    margin-top: 1rem;

    .select-group {
      max-width: 18rem;
    }
  }

  .answer-section {
    margin-top: 1rem;

    .header {
      border-bottom: 1px solid $c-answer-header-border;
      max-width: 16rem;
      width: 100%;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      color: $c-dark-gray;
    }
  }

}

.msar-layout .pc2-signature-box {
  font-size: 0.9rem;
  max-width: 35rem;
}

.answer-header {
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  color: $c-text-dark;
}
