// Buttons

#{$all-buttons} {
  @include transition(all $tr);
  display: inline-block;
  color: $w;
}

#{$all-buttons-focus},
#{$all-buttons-hover} {
  outline: none !important;
}

#{$all-buttons-active} {
  outline: none;
  color: inherit !important;
}

a.btn {
  text-decoration: none;
}

.btn-custom {
  color: $w;
  text-transform: uppercase;
  padding: 13px 14px;
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  border: none;
  border-radius: $general-border-radius;
  text-decoration: none;
  &:hover {
    background: darken($c-button-bg,10%);
  }
}

.btn-primary-action {
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 15px;
  color: $w;
  //background: $c-light-blue-4;
  border-radius: $general-border-radius;
  font-weight: 600;
  border: none;
  text-transform: uppercase;
  text-decoration: none;
  //&:hover, &:active, &:focus {
  //  background: darken($c-light-blue-4,10%);
  //  color: $w;
  //}
}//btn-primary-action

.btn-gray {
  background: #888;
}
