.aside-holder {
  display: flex;
  @include xs {
    flex: 1 1 100%;
  }
  //flex-grow: 1;
  //width: 100%;
}

aside.menu {
  padding: 0;
  border: 1px solid $c-border-gray;
  border-radius: $general-border-radius;
  overflow: hidden;
  flex-grow: 1;
  @include xs {
    margin-bottom: 5px;
  }

  .menu-header {
    padding: 5px 15px;
    //background: $c-light-gray;
    display: flex;
    justify-content: space-between;
    display: none;
    @include xs {
      display: block;
      cursor: pointer;
    }
    span {
      font-size: 22px;
      vertical-align: middle;
    }
    .header {
      display: inline;
      vertical-align: middle;
      color: $c-text-dark;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
      line-height: 20px;
    }
    .icon-hamburger {
      color: $c-text-dark;
      font-size: 20px;
      display: inline;

    }
  }

  .menu-headline {
    padding: 15px;
    border-bottom: 1px solid $c-border-gray;
  }

  .menu-content {
    //background: $c-light-gray-2;
    li {
      padding: 5px 10px 5px 15px;
      cursor: pointer;
      line-height: 2em;
      border-bottom: 1px solid $c-light-gray-6;
      @include linear-gradient(to top, $c-light-gray-2, $w);
      a {
        color: $c-text-dark-4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        &:focus, &:active {
          color: $c-text-dark;
        }
      }
      &:hover {
        background: $c-general-blue;
        border-bottom-color: $c-hovered-item-border-bottom;
        @include linear-gradient(to top, $c-hovered-item-bg-start, $c-hovered-item-bg-end);

        a {
          color: $w;
        }

        [class^="icon-chevron"] {
          color: $w;
        }

      }

      [class^='icon-'] {
        color: $c-light-gray-8;
      }

    }
  }

}