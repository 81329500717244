.carousel-section {
  height: 370px;

  &.owl-carousel {
    display: flex;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;

    .owl-wrapper-outer {
      border-radius:$general-border-radius;
      border: 1px solid $c-caro-item-border;
      overflow: hidden;
      z-index: 10;
    }

    .owl-wrapper {
      display: none;
    }
  }

  .item, .owl-item, .owl-wrapper, .owl-wrapper-outer {
    height: 100%;
  }

  .owl-controls {
    position: absolute;
    top: 0;
    right: 0.9375rem;
    .owl-page {
      display: inline-block;
      span {
        display: block;
        width: 12px;
        height: 12px;
        margin: 5px 7px;
        opacity: 0.5;
        border-radius: 20px;
        background: $c-general-blue;
      }
      &.active {
        span {
          opacity: 1;
        }
      }
    }
  }

  .item {
    display: flex;
    align-items: center;
    padding: 15px;
    position: relative;
    justify-content: center;
    //background: $c-caro-slide-bg;
    &.wistia {
      padding: 0;
    }

    .bg-image {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      @include sm {
        background: #f2f0f2;
      }
      img {
        height: 100%;
        width: 100%;
        @include sm {
          display: none;
        }
      }
    }

    .slide-content {
      height: 100%;
      width: 100%;
      .headline {
        font-size: 1.625rem;
        font-weight:600;
        color: $c-section-top-border;
        margin-bottom: 1rem;
        @include xs {
          font-size: 1.2rem;
        }
      }
      ul {
        flex-direction: column;
        li {
          display: flex;
          align-items: center;
          margin-top: .75rem;
          @include xs480 {
            margin-top: 5px;
          }
        }
        .icon-tick {
          font-size: 1.625rem;
          line-height: 1.625rem;
          color: $c-caro-tick;
          @include xs480 {
            font-size: 17px;
          }
        }
        .text-line {
          font-family: 'Roboto';
          font-size: 0.8rem;
          line-height: 1rem;
          color: $c-text-dark-3;
          max-width: 21.25rem;
          @include xs480 {
            font-size: 10px;
          }
        }
      }
    }
  }

}

