body, html { width: 100%; height: 100%; margin: 0; padding: 0 }

/*
-----------------------------------------
"display: block" for custom elements:
*/
:not(:defined) {
  display: block;
}

/* and in case if browser doesn't support this selector, important elements: */
nav-footer, have-a-question {
  display: block;
}

/*---------------------------------------*/

.btn-success, .btn-info, .btn-danger, a.btn-success:not([href]), a.btn-info:not([href]), a.btn-danger:not([href]) {
  color: #fff;
}

.menu-search {
  background-color: rgba(135, 135, 135, 0.51);
  color: #fff;
}

[href], .pointer {
  cursor: pointer;
  /*[ng-reflect-router-link] doesn't work in production mode */
}

.cursor-disabled {
  cursor: not-allowed;
}

.cursor-wait {
  cursor: wait;
}

select.form-control {
  height: 2.25rem;
}

.text-inverse {
  color: #fff;
}

.card-secondary {
  background-color: #818a91;
  color: #fff;
}

.nav-link .material-icons, td.material-icons, .list-group-item .material-icons, .material-icons.sm {
  font-size: 1rem;
}

.tight-row {
  margin: 0;
}

.tight-row > * {
  padding-left: 0;
  padding-right: 0;
}

.no-pad-right {
  padding-right: 0;
}

.nav-tabs .nav-item {
  cursor: pointer;
}

td .label {
  font-size: 1rem;
}

.pc2-row-pad {
  margin-top: 1em;
}

.pc2-row-pad-bigger {
  margin-top: 1.5em;
}

.pc2-row-pad-big {
  margin-top: 2em;
}

.pc2-row-pad-sub-input {
  margin-top: 0.25rem;
}

.pc2-bottom-pad {
  margin-bottom: 4em;
}

input:invalid {
  box-shadow: none;
}

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pc2-spinner {
  display: inline-block;
  animation: anim-rotate 2s infinite linear;
}

[data-hidden="true"] {
  display: none !important;
}

.pc2-color-x {
  color: #01aeef;
}

.pc2-color-y {
  color: #3F6184;
}

.pc2-bgcolor-x {
  background-color: #01aeef;
}

.pc2-bgcolor-gray {
  background-color: #e5e5e5;
}

.pc2-bgcolor-card {
  background-color: #e5e5e5;
}

.progress.pc2-color-x[value]::-webkit-progress-value {
  background-color: #01aeef;
}

.progress.pc2-color-x[value]::-moz-progress-bar {
  background-color: #01aeef;
}

.progress.pc2-color-x, progress.pc2-color-x[role][aria-valuenow] {
  background: #cbeffc !important;
}

.progress.pc2-color-x::-webkit-progress-bar {
  background: #cbeffc;
}

.color-danger {
  color: red !important;
}

.color-danger-hover:hover {
  color: red !important;
}

.color-danger-hover {
  color: rgba(66, 6, 6, 0.6) !important;
}

.color-warning {
  color: #f0ad4e !important;
}

.bg-color-danger {
  background-color: red !important;
}

.bg-color-success {
  background-color: #1a8468 !important;
}

.bg-color-success-light {
  background-color: #17d637 !important;
}

.color-success-light {
  color: #17d637 !important;
}

.bg-color-disabled {
  background-color: #ddd !important;
}

.color-disabled {
  color: #ddd !important;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

.pc2-logo-embedded {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  margin: auto;
  display: inline-block;
}

.pc2-form-group {
  margin-bottom: 1rem;
}

.copy-bg {
  background-color: rgba(239, 239, 239, 0.33);
}

.highlight {
  background-color: #ffff00 !important;
}

.purple {
  color: #b10dc9;
}

.dropdown-triangle-down::after {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

.dropdown-triangle-up::after {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  border-bottom: 0.3em solid;
}

.high-modal {
  min-height: 50vh;
}

.zoomed-checkbox {
  transform: scale(1.5);
  margin: 4px;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
}

.tab-borderd {
  border: 1px solid #e8eae9;
  border-top: none;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  padding: 20px 15px 30px;
}

.material-icons-in-link {
  top: 0.3rem;
  position: relative;
}

.sm.material-icons-in-link {
  top: 0.2rem;
  position: relative;
}

.no-line-height {
  line-height: 0;
}

.color-success {
  color: #5cb85c;
}

small > .material-icons {
  font-size: 1rem;
  position: relative;
  top: 0.2rem;
}

.crm-logo-embedded {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  margin: auto;
  text-align: center;
  display: inline-block;
}

.crm-distr-circle {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  text-align: center;
  display: inline-block;
  position: relative;
  border: rgba(0, 0, 0, 0.2) solid 1px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.crm-distr-circle-container {
  float: left;
  width: 25%;
  padding: 5px;
  text-align: center;
}

.crm-logo-distr {
  height: 50px;
}

.img-logo-padded {
  padding: 0.5rem;
}

.h1.pc2-h1-centered {
  text-align: center !important;
  line-height: 5rem;
}

.crm-distr-circle .material-icons {
  font-size: 5em;
  line-height: 1.1em;
  padding-left: .18em;
  display: table-cell;
  cursor: default;
  position: relative;
  top: 2px;
  left: -1px;
  font-weight: 400 !important;
}

.crm-distr-circle-container hr {
  margin: 0 0 0.5em 0;
}

.compact-ul {
  margin-bottom: 0
}

#header.header-wb, #header.header-wb > *, .header-wb .header, .header-wb .phone, .header-wb, .header-wb .account-dropdown .dropdown .dropdown-toggle:after {
  background: none !important;
  background-color: #fff !important;
  color: #000 !important;
  text-shadow: none !important;
}

.header-wb .input-group-append {
  background: none !important;
  background-color: rgb(128, 128, 128) !important;
}

.header-wb input.search-input {
  border-color: rgba(200, 200, 200, 1);
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.row-iframe-container { display: table; empty-cells: show; border-collapse: collapse; width: 100%; height: 100%; }

.iframe-container { display: table-row; height: 100%; overflow: hidden }

.iframe-container iframe { width: 100%; height: 100%; border: none; margin: 0; padding: 0; display: block; }

.pc2-pre-text {
  font-size: 0.8rem;
}

.pc2-row-wide {
  margin-right: -6rem;
  margin-left: -6rem;
}

.pc2-search-icon {
  color: white;
  font-size: 25px;
  position: relative;
  top: 3px;
  left: 1px;
}

.pc2-tmp-hide {
  display: none !important;
}

.font-sm {
  font-size: 0.9rem;
}

.center-content-flex {
  justify-content: center;
  align-items: center;
  display: flex;
}

.material-icons.md-18 { font-size: 18px; }

.material-icons.md-24 { font-size: 24px; }

.material-icons.md-36 { font-size: 36px; }

.material-icons.md-48 { font-size: 48px; }

.pc2-tight-card-body {
  padding: 0.9rem;
}

.color-success-bright {
  color: #37c428;
}

.pc2-big-checkbox {
  margin: 0.5em;
}

.pc2-big-checkbox > input {
  margin: 0.5em 1em;
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
}

.pc2-rx-signature {
  font: 400 80px/1.0 'Dancing Script', Helvetica, sans-serif;
  color: #0175D8;
  line-height: 1.5em;
  text-align: center;
}

.pc2-link {
  text-decoration: underline;
  cursor: pointer;
  color: #01aeef;
}

.pc2-link:hover {
  text-decoration: none;
}

.table-vaccines th {
  color: #fff;
  background-color: #044983;
  text-align: center;
  vertical-align: middle;
}

.table-vaccines td {
  color: #044983;
  vertical-align: middle;
}

.pc2-inverse {
  color: #fff;
  background-color: #044983;
}

.hidden {
  display: none !important;
}

.pc2-align-sub-images img {
  width: 100%;
}

.modal-dialog {
  max-width: 50vw;
}

.modal-dialog.modal-wide {
  max-width: 70vw;
}

.modal-dialog.modal-wide50 {
  max-width: 50vw;
}

.modal-dialog.modal-high {
  min-height: 70vh;
}

.modal-dialog.modal-high50 {
  min-height: 50vh;
}

.force-wide-high-70 {
  min-height: 70vh !important;
  min-width: 70vw !important;
}

.force-wide-high-sub-70 {
  min-height: 70vh !important;
  min-width: 68vw !important;
}

.force-wide-high-80 {
  min-height: 80vh !important;
  min-width: 80vw !important;
}

.force-wide-high-sub-80 {
  min-height: 80vh !important;
  min-width: 78vw !important;
}

.force-wide-100-high-80 {
  min-height: 80vh !important;
  min-width: 99vw !important;
}

.force-wide-100-high-80-sub {
  min-height: 80vh !important;
  min-width: 97vw !important;
}

.color-primary {
  color: #0275d8;
}

.dropdown-menu a {
  cursor: pointer;
}

.card {
  margin-bottom: 0.5rem;
}

.card-header.solo {
  padding: 0.5rem 0.75rem;
  border-bottom: none;
}

.pc2-terms-link {
  position: relative;
  top: 0.75em;
}

.single-border-input {
  background-color: transparent;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid;
}

.btn-bordered {
  border-radius: 0.9em;
}

.btn-lg.btn-bordered {
  border-radius: 1em;
}

.btn-sm.btn-bordered {
  border-radius: 0.7em;
}

.label-lg {
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 0;
}

.full-width {
  width: 100%;
}

.font-size-1 {
  font-size: 1rem !important;
}

.pc2-wait, .pc2-loading, .pc2-disabled, [disabled].animate-disabled {
  cursor: progress;
  -webkit-animation: opacityCycle 2.5s linear infinite;
  -moz-animation: opacityCycle 2.5s linear infinite;
  animation: opacityCycle 2.5s linear infinite;
}

.opacity-cycle {
  -webkit-animation: opacityCycle 2.5s linear infinite;
  -moz-animation: opacityCycle 2.5s linear infinite;
  animation: opacityCycle 2.5s linear infinite;
}

@-webkit-keyframes opacityCycle {
  0% { opacity: 0.6 }
  50% { opacity: 0.15 }
  100% { opacity: 0.6 }
}

@keyframes opacityCycle {
  0% { opacity: 0.6 }
  50% { opacity: 0.15 }
  100% { opacity: 0.6 }
}
.ta-width-100 {
    width: 100% !important;
}