.contact-layout {
  .phone-holder {
    .form-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      label {
        align-self: stretch;
        width: 100%;
      }
      input {
        width: auto;
        margin-right: 10px;
        @include placeholder {
          padding-left: 0px;
        }
      }
    }
  }

  textarea {
    max-height: 200px;
  }

  .submit-holder {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
  }
}
