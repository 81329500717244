.cart-content {
  margin-top: 20px;
  h1 {
    margin: 0;
    font-size: 1.5rem;
  }

}

.cart-table-wrap {
  border: 1px solid $c-section-border;
  border-radius: 8px;
  background: #fff;
  display: block;
  margin-bottom: 60px;
  overflow: hidden;


  .table-header {
    padding: 15px;
    margin-bottom: 10px;
    background: $c-light-gray-2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include xs {
      margin-bottom: 0;
    }
  }
  
  .cart-item {
    border-bottom: 1px solid $c-light-gray-3;

    .btn-remove {
      @include xs {
        margin-top: 10px;
      }
      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }

    .contract-img {
      padding: 10px 0;
    }
  }

  .contract-description {
    padding-top: 15px;
  }

}

.cart-info {
  margin-top: 20px;

  @include sm {
    width: 100%;
  }
  .cart-status-holder {
    text-align: center;
  }
  .actions-holder {
    display: flex;
    align-items: center;
    flex-direction: column;
    button {
      margin-bottom: 15px;
      align-self: center;
      width: 200px;
    }
  }
}
