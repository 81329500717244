.signup-layout {
  color: $c-dark-gray;
  display: flex;
  justify-content: center;
  @include xs480 {
    padding: 0 0.9375rem;
  }

  .bordered-container {
    max-width: 100%;
    @include xs480 {
      width: 100%;
    }
  }

  .bordered-content {
    border-top: 1px solid $c-section-border;
    padding: 1.25rem;
    min-width: 22.5rem;
    @include xs480 {
      width: 100%;
      min-width: initial;
    }
  }

  .headline {
    font-size: 1.75rem;
    margin: 0 0 0.9375rem;
    color: $c-headline;
  }


  .form-group {
    flex-direction: column;
  }

  input {
    border-radius: $general-border-radius;
    font-size: 14px;
  }


  a {
    color: $c-footer-links;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $c-footer-links;
    }
  }

  .forgot-pwd-link {
    font-size: 15px;
  }


}

.signup-wrapper {
  max-width: 600px;
  margin: 20px auto;
}

.signup-content {

  .sign-news-holder {
    display: flex;
    align-items: center;
  }

}