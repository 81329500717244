.breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0 0 15px;
  font-size: 14px;

  a {
    color: $c-light-blue-4;
  }

  .breadcrumb-item {
    &.active {
      color: #616568;
    }
    + .breadcrumb-item {
      &:before {
        font-family: 'icomoon';
        content: "\e912";
        font-size: 10px;
        color: #c4c4c6;
      }
    }
  }

}
