.featured-contracts {
  background: $c-featured-contracts-bg;

  position: relative;

  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 40px;
  color: $c-h-color;
  text-align: left;

  @include xs {
    line-height: initial;
  }

  .top-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include xs {
      flex-direction: column;
      align-items: stretch;
    }
    .header {
      font-size: 28px;
      line-height: 28px;
      color: $c-text-dark-2;
      padding: 40px 0 30px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0;
      @include xs {
        padding-bottom: 0;
      }
    }
    a {
      font-size: 14px;
      line-height: 14px;
      position: relative;
      top: 10px;
      min-width: 130px;
      @include xs {
        padding: 20px 0;
      }
    }
  }

  .bx-carousel-section {
    position: relative;

    .bx-viewport {
      border: none;
      box-shadow: none;
      background: transparent;
      left: 0;

      .bx-slider {
        display: flex;
        .item {
          display: flex;
          max-width: 258px;
          margin-right: 25px;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }


    #bx-slider-prev a,
    #bx-slider-next a {
      position: absolute;
      height: 100%;
      top: 0;
      display: flex;
      z-index: 10;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      color: $c-fc-slider-controls;
      text-decoration: none;
      [class^='icon'] {
        font-size: 50px;
        margin: 0;
      }
      &.disabled {
        color: $c-fc-slider-controls-disabled;
      }
    }
    #bx-slider-prev a {
      left: -60px;
    }
    #bx-slider-next a {
      right: -60px;
    }
  }

  span.content {
    //background-color: #fff;
    padding-right: 20px;
    display: inline;
  }

  .item {
    position: relative;
    flex-direction: column;
    background: $w;
    border: 1px solid $c-border-gray;
    border-radius: $general-border-radius;
    overflow: hidden;
    padding-bottom: 20px;

    .img-holder {
      padding: 25px 3px 3px 3px;
      position: relative;
      overflow: hidden;
      background: $w;
      height: 206px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        display: block;
        height: auto;
        max-height: 100%;
      }
    }

    .header {
      color: $c-text-dark-3;
      strong {
        color: $c-text-dark-2;
      }
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      padding: 20px 0 2px;
      font-weight: 400;
      margin: 0 0 15px 0;

    }

    .subheader {
      color: $c-text-dark-3;
      font-size: 13px;
      text-align: center;
      padding: 0 0 30px;
      flex-grow: 1;
    }

    .item-info {
      text-decoration: none;
      font-size: 0.8em;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
      padding: 0 15px;

      @include xs {
        padding: 0 5px;
      }

      .header {
        padding-top: 10px;
      }
    }

    .ii-header {
      flex-grow: 1;
    }

    .ii-stats {
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: 0 15px;
      @include xs {
        padding: 0;
      }
      .rating {
        margin-left: 8px;
      }
    }

    .ii-rev {
      color: $c-light-blue-4;
      font-size: 13px;
      text-decoration: underline;
    }

    .item-actions {
      text-align: center;
      margin-top: 15px;
      width: 100%;
      border-radius: 5px;
      transition: opacity 0.5s ease;

      @include xs {
        padding: 0 5px;
      }

      .item-action {
        @extend .btn-primary-action;
        //font-size: 14px;
        //line-height: 16px;
        //padding: 10px 15px;
        //background: #15b3e9;
        //border-radius: 7px;
        //font-weight: 600;
        //border: none;
        //text-transform: uppercase;
        //text-decoration: none;
        //&:hover {
        //  background: #118fba;
        //}
      }
    }//item-actions
  }

}
