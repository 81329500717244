.с-w {
  color: $w !important;
}
.с-b {
  color: black !important;
}

.no-mp {
  margin: 0; padding: 0;
}

.pos-rel {
  position: relative;
}

.d-flex {
  display: flex !important;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow {
  flex-grow: 1;
}

.w-100 {
  width: 100%;
}

.maxw-15 {
  max-width: 15rem !important;
}

.maxw-17 {
  max-width: 17rem !important;
}

.mxw-100 {
  max-width: 100%;
}

.ov-h {
  overflow: hidden;
}

.flex-stretched {
  display: flex;
  align-items: stretch;
}

.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-vcentered {
  display: flex;
  align-items: center;
}


.opacity-1 {
  opacity: 1 !important;
}

.zi-999 {
  z-index: 999 !important;
}

.hidden {
  display: none !important;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.fz09 {
  font-size: .9em;
}

.upper {
  text-transform: uppercase;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.pt1 {
  padding-top: 1rem;
}

.bg-green {
  background: lightgreen;
}

.bg-red {
  background: lightsalmon;
}

.color-inherit {
  color: inherit;
}
