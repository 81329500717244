// Containers
.bordered {
  @include bordered(20px 15px 30px);
}

.form-section {
  margin-bottom: 30px;
}

.tall {
  margin: 30px 0;
  background-image: linear-gradient(to right,transparent,rgba(0,0,0,.2),transparent);
  border: 0;
  height: 1px;
}


.form-action {
  float: right;
  margin-top: 10px;
  .notice {
    color: $c-red;
    font-size: 12px;
    margin-bottom: 15px;
  }
  .btn-action {
    background: $c-form-btn-action;
    border: none;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 0.875rem;
    text-transform: uppercase;
    border-radius: $general-border-radius;
    padding: 0.5rem 0.625rem;
    span {
      vertical-align: middle;
    }
    &:hover {
      background: darken($c-form-btn-action,10%);
    }
    &.ico-right {
      [class^='icon-'] {
        margin-left: 0.5rem;
        margin-right: 0;
      }
    }
  }
}

.custom-form-control {
  font-size: 13px;
  color: $c-input;
}

// Icons

[class^='icon-'] {
  margin-right: 5px;
  font-size: 20px;
  vertical-align: middle;
}

// Labels, inputs, selects, radio

label {
  font-size: 14px;
  position: relative;
}

.form-list {
  >li {
    display: flex;
  }
}

input {
  color: $c-input;
  font-size: 14px;
  font-family: $f-primary;
  border-radius: 7px;
  @include placeholder {
    color: $c-input-placeholder;
    font-family: $f-primary;
  }
}

.input-group {
  &.vertical {
    flex-direction: column;
  }
}

.custom-input-group, .form-group, .answer-holder {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 3px;
  clear: both;

  &.required {
    label:after {
      float: right;
      font-style: normal;
      color: $c-red;
      position: absolute;
      top: 0;
      right: -8px;
      content: '*';
    }
  }

  .form-control-label {
    padding-left: 0;
    padding-top: 0;
    color: $c-headline;
    font-weight: 600;
    font-size: 0.875rem;
  }

  input[type="text"],
  input[type="email"]
  {
    height: $edit-height;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 100%;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    &.invalid-value {
      border: 1px dashed #eb340a !important;
      background: #faebe7 !important
    }
  }


  &.select-group {
    label {
    }
    select {
      display: block;
      height: $edit-height;
      background: $w;
      border: 1px solid $c-light-gray-4;
      padding: 2px 8px;
      color: $c-dark-gray;
      font-size: 13px;
      border-radius: 6px;
    }
  }

  &.checkbox-group {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    height: auto;
    label {
      margin-bottom: 0.75rem;
      padding-left: 10px;
    }
    input {
      width: auto;
      height: auto;
      padding: 10px 0;
    }
  }

  &.radio-group {
    line-height: 14px;
    display: flex;

    @include sm {
      flex-direction: column;
    }

    .group-header {
      display: block;
      margin-bottom: .5rem;
      font-size: 14px;
      position: relative;
      color: $c-dark-gray;
    }
    label {
      margin: 0 2rem 0 0;
      display: flex;
      align-items: center;
      @include sm {
        margin-bottom: 0.5rem;
      }

      input {
        height: auto;
        width: auto;
        margin: 0 0.5rem 0 0;
        flex-shrink: 0;
      }
    }
  }

  //Not used now
  &.vertical-radio-group {
    @extend .radio-group;

    label {
      display: block;
      text-align: left;
      max-width: 16rem;
      width: 100%;
      padding: 0;
      text-indent: 2rem;
      margin-bottom: 1rem;
    }
  }

  &.label-left {
    display: flex;
    align-items: center;
    label {
      order: 0;
      margin: 0 10px 0;
    }
    .custom-form-control {
      order: 1;
    }
  }


}

.form-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  &.centered {
    justify-content: center;
  }
}
// Other

.tooltip-inner {
  background: $c-general-blue;
}

.embed-responsive {
  margin-bottom: 15px;
}
