.description-holder {

  .headline {
    line-height: 1;
  }

  .contract-details {
    @extend .list-unstyled;
  }

  .contract-actions {
    padding-top: 0.25em;

    .btn {
      padding: 11px 12px 9px;
      margin-bottom: 10px;
      font-size: 14px;
      border-radius: 5px;
      line-height: 1;
      [class^='icon-'] {
        position: relative;
        top: -1px;
        margin-right: 12px;
      }
    }
  }

}
