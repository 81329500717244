* {
  //For debugging
  //outline: 1px dashed red;
}

/* --------------------------------
Typography
-------------------------------- */
html {
  font-family: $f-primary;
  font-weight: 400;
  color: $c-text-general;
}

input {
  &:focus {
    @include placeholder {
      opacity: 0;
    };
  }
}

textarea {
  &:focus {
    @include placeholder {
      opacity: 0;
    };
  }
}

