.checkout-layout {

  .distr-section {
    padding: 0 15px;
  }

  .input-group {
    &.label-left {
      label {
        width: 70px;
      }
    }
  }

  .signature-copy-holder {
    border-bottom: 1px solid $c-dark-gray;
    height: 3.5rem;
  }
  #signature-view {
    font-family: 'cookie';
    font-size: 3rem;
  }

  #tosBox {
    //width: 95%;
    height: 300px;
    overflow: auto;
    border: 1px solid #ccc;
    h2, h3 {
      margin: 1em 0 0.5em;
      padding: 0 0 6px;
      border-bottom: 1px solid #ccc;
      font-size: 2rem;
      color: $c-text-general;
    }
    h2 {
      font-size: 2.5rem;
    }
  }
}

