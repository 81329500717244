@import "description";
@import "table";

.contract-layout {
  color: $c-text-dark-3;
  margin: 0 0 40px 0;
  strong, .strong {
    color: $c-headline;
  }

  h1 {
    margin-bottom: 30px;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .carousel-section {
    max-width: 45.625rem;
    margin: 0;
    @include sm {
      margin-bottom: 1rem;
    }
  }

  .contract-item {
    border: 1px solid $c-item-border;
    border-radius: $general-border-radius;
    overflow: hidden;
    margin: 0 0 15px;
    padding: 15px 0;

    >[class^='col-'] {
      padding-left: .5rem;
      padding-right: .5rem;
      &:first-child {
        padding-left: 0.9375rem;
      }
    }

    ul.category {
      margin-bottom: 10px;
      font-size: 14px;
      li {
        line-height: 25px;
      }
    }

    .img-holder {
      border: none;
    }

    .item-footer {

    }
  }

  .img-holder {
    border: 1px solid $c-item-border;
    border-radius: $general-border-radius;
    overflow: hidden;
  }

  .description-holder {
    .headline {
      font-weight: 600;
    }
    .contract-dates {
      margin-bottom: 25px;
    }
  }
}

.nav-tabs .nav-link {
  &.active, &.active:focus, &.active:hover {
    border-top: 1px solid $c-general-blue;
    border-width: 3px 1px 0 1px;
    border-radius: 5px 5px 0 0;
  }
  &:hover {
    color: $c-dark-gray;
  }
}
