.customize-exp {
  margin: 0;
  padding: 25px;
  margin-bottom: 30px;

  background: $c-customize-exp-bg;
  border-radius: $general-border-radius;
  border: 2px dashed #dbdcde;
  overflow: hidden;

  > .row {
    flex-wrap: nowrap;
    @include sm {
      flex-wrap: wrap;
    }
  }

  .custom-input-group {
    margin-bottom: 0;
  }

  .header-holder {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    @include xs {
      flex-grow: 1;
      justify-content: center;
    }
  }

  .header {
    color: $c-customize-exp-text;
    font-size: 22px;
    font-style: normal;
    margin: 0;
    flex-shrink: 1;
    white-space: nowrap;
    max-width: 100%;
    @include md {
      white-space: normal;
    }
    @include xs {
      text-align: center;
    }
  }

  .controls-holder {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include xs {
      justify-content: center;
      width: 100%;
      margin-top: 15px;
    }
    @include xs480 {
      justify-content: space-between;
      flex-direction: column;
    }

  }

  .select-holder {
    display: flex;
    flex-grow: 1;
    @include xs {
      flex-grow: 0;
    }
    @include xs480 {
      flex-direction: column;
      align-self: stretch;
    }
  }

  .select-group {
    max-width: 200px;
    display: inline-block;
    margin-right: 15px;
    @include xs480 {
      margin-bottom: 15px;
      max-width: initial;
    }
  }

  .btn-holder {
    display: flex;
    align-items: stretch;
    margin-left: 5px;
    @include xs480 {
      align-items: center;
    }
  }

  .btn-apply {
    font-size: 1rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    &:hover {
      //background: $w;
      //color: $c-customize-exp-bg;
    }
  }
}
