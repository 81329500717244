nav-header {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  .navbar-nav {
    @include xs {
      padding-bottom: 15px;
    }
  }

  .navbar {
    background: $c-header-navbar-bg;
    color: $w;
    border-radius: 0;
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    align-items: center;
    @include xs {
      align-items: flex-start;
    }

    .navbar-toggler {
      width: 30px;
      height: 21px;
      position: relative;
      margin: 1.25rem auto 1rem;
      transform: rotate(0deg);
      padding: 0;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $w;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
      }

      &.collapsed span {
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2) {
          top: 9px;
        }
        &:nth-child(3) {
          top: 18px;
        }
      }

      &:not(.collapsed) span {
        &:nth-child(1) {
          top: 10px;
          transform: rotate(135deg);
        }
        &:nth-child(2) {
          opacity: 0;
          left: -60px;
        }

        &:nth-child(3) {
          top: 10px;
          transform: rotate(-135deg);
        }
      }


    }

    >.container {
      width: 100%;
    }

    .nav-item {
      position: relative;
      font-size: 15px;
      +.nav-item {
        margin-left: 35px;
        @include sm {
          margin-left: 18px;
        }
      }
      @include xs {
        float: none;
        margin-left: 18px;
      }

      .dropdown-menu {
        border: 0;
        box-shadow: 1px 1px 10px 0px rgba(50, 50, 50, 0.35);
        padding-left: 15px;
        padding-right: 15px;
        margin-top: $general-border-radius;
        &::before {
          content: '';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: 5px;
          background: $c-light-blue-2;
        }
        //border-top: 5px solid $c-light-blue-2;
        border-radius: 0;
        .dropdown-item {
          color: $c-header-navbar-dropdown-item;
          border-bottom: 1px solid $c-light-gray-6;
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          padding: 15px 0;
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background: none;
            color: $c-light-blue-3;
          }
        }
      }

      .nav-link {
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 500;
        @include sm {
          font-size: 15px;
        }
      }

      .nav-link
      {
        color: $w;
      }

      .nav-link:hover,
      .nav-link:focus
      {
        color: $c-light-blue-2;
      }

      &.active, &.open {
        .nav-link,
        .nav-link:hover,
        .nav-link:focus
        {
          color: $c-light-blue-2;
        }
      }

      .icon-home {
        position: relative;
        top: -3px;
      }
      .span-home {
        @include xs {
          display: inline-block !important;
        }
      }

    }

  }
}
