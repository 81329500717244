#header
{
  @include background(linear-gradient($c-header-bg-from, $c-header-bg-to) top repeat);
  border-bottom: 1px solid $c-header-bb;
  min-height:    100px;
  color:         $w;
  position:      relative;

  display:       flex;
  align-items:   center;
  @include xs
  {
    //padding: 10px 0;
  }

  > .container
  {
    flex-grow: 1;
  }

  .media
  {
    margin-bottom: 0;
  }

  .header-content
  {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    @include xs
    {
      text-align: center;
      padding:    1rem 0;
      flex-wrap:  wrap;
      justify-content: space-around;
    }
  }

  .logo-holder
  {
    display: contents;
    flex: 20;
    flex-basis: 20%;
    @include xs
    {
      flex-basis:    100%;
      margin-bottom: 1rem;
      max-width:     100%;
    }
    img
    {
      @include img-fluid();
      max-height: 100px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .actions-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 100%;
  }

  .phone-holder
  {
    text-align: left;
    padding:    0 40px;
    @include sm
    {
      padding: 0 20px;
    }
    .header
    {
      font-size:     13px;
      color:         $w;
      text-shadow:   0 0 5px $c-header-text-shadow;
      font-family:   $f-primary;
      font-weight:   600;
      margin-bottom: 6px;
      white-space:   nowrap;
    }
    .phone
    {
      font-size:       18px;
      line-height:     18px;
      font-weight:     600;
      color:           $c-header-phone;
      text-decoration: none;
    }
  }

  .btn-holder
  {
    display:     flex;
    align-items: center;
    .btn-signup, .btn-login
    {
      height:      36px;
      font-size:   14px;
      font-weight: bold;
      padding:     0 12px;
      display:     flex;
      align-items: center;
    }

    .btn-signup
    {
      border:       2px solid $w;
      background:   transparent;
      margin-right: 10px;
      &:hover
      {
        background: $w;
        color:      $c-btn-signup-hover;
      }
    }

    .btn-login
    {
      background: $c-btn-login;
      padding:    0.8125rem 1.25rem;
      &:hover
      {
        color:      $w;
        background: $c-btn-login-hover;
      }
    }

  }

  .account-dropdown
  {
    display:         flex;
    justify-content: center;
    .dropdown
    {
      display:        flex;
      flex-wrap:      nowrap;
      flex-direction: row;

      .dropdown-toggle
      {
        cursor:          pointer;
        text-decoration: none;
        position:        relative;
        &:after
        {
          position:   absolute;
          right:      0;
          top:        50%;
          margin-top: -3px;
          color:      $w;
        }
      }
      //dropdown-toggle

      .dropdown-menu
      {
        border-radius: 0;
        border:        0;
        box-shadow:    1px 1px 10px 0 rgba(50, 50, 50, 0.35);
        right:         0;
        left:          auto;
        padding:       15px;

        .dropdown-item
        {
          display:        flex;
          align-items:    center;
          flex-direction: row;
          flex-wrap:      nowrap;
          color:          $c-header-navbar-dropdown-item;
          border-bottom:  1px solid $c-light-gray-6;
          line-height:    2em;
          font-size:      14px;
          padding-left:   0;
          padding-right:  0;
          &:last-child
          {
            border-bottom: none;
          }

          &:hover
          {
            background: none;
            color:      $c-light-blue-3;
          }
        }
        a
        {
          color:           $c-header-navbar-dropdown-item;
          text-decoration: none;
        }
      }
      //dropdown-menu

      .account-content
      {
        padding-right: 20px;
        align-items:   center;
        > .media-body
        {
          color: $w;
          min-width: 5rem;
          @include xs {
            min-width: auto;
          }
        }
        > .media-left
        {
          padding:      0;
          margin-right: 10px;
        }
        .avatar-holder
        {
          background:      $w;
          border-radius:   50%;
          height:          46px;
          width:           46px;
          display:         flex;
          align-items:     flex-end;
          justify-content: center;
          img
          {
            width:         80%;
            max-width:     80%;
            border-radius: 50%;
          }
        }
      }
      //account-content
    }
  }
}


.search-box-holder
{
  @include md
  {
    width: auto;
  }
  .search-box
  {
    transition:    opacity .5s ease;
    border:        none;
    overflow:      hidden;
    max-height:    40px;
    margin-bottom: 0;
    border-radius: $general-border-radius;
    display:       flex;
    flex-wrap:     nowrap;
    @include xs
    {
      position: absolute;
      width:    90%;
      bottom:   0;
      left:     5%;
      opacity:  0;
      z-index:  -1;
    }
    input
    {
      border: 0;
      height: 100%;
    }
    .input-group-addon
    {
      text-decoration: none;
      background:      #00b1eb;
      border:          none;
      .icon-search
      {
        line-height:  1.5;
        color:        $w;
        margin-right: 0;
      }
    }
  }

  .search-box-xs
  {
    display: none;
    z-index: 10;
    @include xs
    {
      display: inline-block;
    }
    .icon-search
    {
      line-height: 1.5;
      color:       $w;
      cursor:      pointer;
      @include xs {
        line-height: 2;
      }
    }
  }
}

header-search-box
{
  flex:          50;
  padding-left:  3rem;
  padding-right: 1rem;
  @include xs {
    flex-grow: 0;
    padding-left: 0;
  }
}

header-search-box .search-input
{
  font-size:    1rem;
  min-height:   40px;
  padding-left: 1rem;
}

header-search-box .search-input:disabled
{
  cursor: progress !important;
}

.search-spinner
{
  color:      #fff;
  margin-top: 5px;
  display:    inline-block;
  font-size:  20px;
  cursor:     progress;
}
