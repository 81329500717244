$brand-pc2:           #01aeef !default;
$btn-pc2-color:       #fff !default;
$btn-pc2-bg:          $brand-pc2 !default;
$btn-pc2-border:      $btn-pc2-bg !default;

.btn-pc2 {
  @include button-variant($btn-pc2-bg, $btn-pc2-border);
}

.btn-pc2-outline {
  @include button-outline-variant($btn-pc2-bg);
}

.mgma {
  background: #228848 !important;
  border-bottom: 1px solid #127519 !important;
}
.mgma-nav {
  background: #000!important;
}
.mgma-nav .nav-item.active a:not(:hover){
  color: #228848 !important;
}
.mgma-nav .nav-item a:hover{
  color: #47c274 !important;
}
.mgma-nav .nav-item a:focus{
  color: #47c274 !important;
}

.mgma-footer {
  background: #228848 !important; 
}
.mgma-theme-logo {
  height: 49px !important;
    width: 186px;
    margin-top: 33px;
  margin-right: -20px;
}
.mgma-link {
  color: #5cfdca;
}
.mgma-svg {
  height: 75px !important;
  margin-left: 15px;
}