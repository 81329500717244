

/* --------------------------------
Variables
-------------------------------- */
// transition time
$tr: 0.5s;

// colors
$w:#fff;

$c-general-blue: #0083c1;
$c-blue-2: #08c;
$c-light-blue: #88eaff;
$c-light-blue-2: #0fc4ed;
$c-light-blue-3: #2dbee3;
$c-light-blue-4: #15b3e9;


$c-gray: #a39f9c;
$c-dark-gray: #777;
$c-dark-gray-2: #535353;
$c-dark-gray-3: #404040;
$c-light-gray: #f5f5f5;
$c-light-gray-2: #fbfbfb;
$c-light-gray-3: #9e9e9e;
$c-light-gray-4: #ccc;
$c-light-gray-5: #eeefef;
$c-light-gray-6: #e8eae9;
$c-light-gray-7: #f3f4f6;
$c-light-gray-8: #d5d7d6;
$c-light-gray-9: #616568;


$c-red: #eb340a;

$c-light-yellow: #f6e67d;
$c-light-yellow-2: #f6e67d;


$c-border-gray: #ddd;
$c-text-general: $c-dark-gray;
$c-text-gray: $c-gray;
$c-text-dark: #283744;
$c-text-dark-2: #2b3946;
$c-text-dark-3: #616568;
$c-text-dark-4: #606467;



$c-item-border: #e8eae9;

$c-link: #30ccf4;
$c-button-bg: #6ab0e4;

$c-headline: #2b3946;

// Sections

//Header
$c-header-bg-from: #116da5;
$c-header-bg-to: #187cb4;
$c-header-bb: #2688bb;
$c-header-text-shadow: #00629f;
$c-header-phone: $c-light-blue;

$c-header-navbar-bg: #044983;
$c-header-navbar-dropdown-item: $c-text-dark-2;

$c-btn-login: #38bca5;
$c-btn-login-hover: #34ae99;
$c-btn-signup-hover: #2075a9;


//Footer
$c-footer-bg: #0b3b67;
$c-footer-links: #30ccf4;
$c-footer-hr: #3F6184;

//Home - top slider
$c-caro-item-border: $c-item-border;
$c-caro-tick: #38bca5;
$c-caro-slide-bg: #f2f0f2;

//Home - Featured contracts
$c-featured-contracts-bg: $c-light-gray-7;
$c-fc-slider-controls: #c4c4c6;
$c-fc-slider-controls-disabled: #dbdcde;

//Home - Categories Menu
$c-hovered-item-border-bottom: #0e4b82;
$c-hovered-item-bg-start: #1d6fa3;
$c-hovered-item-bg-end: #2178ab;

//Market and Savings Analysis Report (msar)

$c-question-header: #00a4da;
$c-answer-header-border: $c-light-gray-4;

//Customize experience section
$c-customize-exp-bg: #f3f4f6;
$c-customize-exp-text: #606467;
$c-customize-exp-border: #dbdcde;
$c-btn-apply: #40d7bc;


//Signup page
$c-signature-bg: #fafafa;
$c-tos-header: #2b3946;
$c-tos-text: #616568;
//EOF Sections


$c-divider: #ddd;

$c-action: #f58345;

$c-h-color: #2b3946;

$c-input: #616568;
$c-input-placeholder: #a0a1a2;

$c-table-row-bg: #f9f9f9;
$c-table-code-cell: #edf1f4;

$c-section-border: #ececec;
$c-section-top-border: $c-blue-2;

$c-form-btn-action: $c-light-blue-4;

// type
$f-primary: 'Open Sans',Arial,Helvetica,sans-serif;
$f-grm: "GothamRnd-Medium", sans-serif;
$f-os: 'Open Sans',Arial,Helvetica,sans-serif;
$f-rbt: 'Roboto',Arial,Helvetica,sans-serif;

// Font Sizes
$base-font-size: 1.1em;

// Line height
$base-line-height: 1.55em;
$heading-line-height: 1em;

// Paddings
$header-height: 120px;
$header-padding-top: 22px;

// Form controls
$edit-height: 36px;
$general-border-radius: 8px;
